<template>
  <div class="order-card-container mt-5">
    <b-form-radio-group @change="changePaymentSelect" class v-model="selected">
      <b-container>
        <b-row>
          <b-col md="7">
            <b-col class="p-0">
              <div class="mb-4">
                <span class="order-header mb-5">Payment Methods</span>
              </div>
              <div class="d-flex mt-0 mb-0">
                <b-form-radio
                  v-model="$store.state['checkout'].paymentMethodClick"
                  value="CASHFREE"
                  class="mt-3"
                  name="radio-size"
                  size="lg"
                >
                  <!-- <h6 style="margin-top: 6px; font-size: 20px">Cashfree</h6> -->
                </b-form-radio>
                <h6 style="margin-top: 15px; font-size: 20px">Cashfree</h6>
              </div>
            </b-col>
            <b-col class="p-0">
              <div class="d-flex">
                <b-form-radio
                  value="TRONADO"
                  v-model="$store.state['checkout'].paymentMethodClick"
                  class="mt-3"
                  name="radio-size"
                  size="lg"
                  disabled
                >
                  <!-- <h6 style="margin-top: 6px; font-size: 20px">Tronado (Coming soon)</h6> -->
                </b-form-radio>
                <h6 style="margin-top: 15px; font-size: 20px; color: #9f9f9f">
                  Tronado (Coming soon)
                </h6>
              </div>
            </b-col>
          </b-col>
          <b-col md="5" class="mt-0">
            <b-container>
              <div class="mb-3">
                <span class="order-header mb-5">Price details</span>
              </div>
              <b-row class="w-200">
                <b-col>
                  <b-card-text class="sub-total">Sub Total:</b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="price-details"
                    >₹ {{ checkoutSubTotal }}</b-card-text
                  >
                </b-col>
              </b-row>
              <hr />
              <b-row class="w-200">
                <b-col>
                  <b-card-text class="total-amount">Total Amount</b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="price-details"
                    >₹
                    {{
                      checkoutData ? checkoutData.totalPrice : ""
                    }}</b-card-text
                  >
                </b-col>
              </b-row>
              <hr />
              <b-col>
                <b-card-text class="price-details mt-3 cashfree-text"
                  >Please Pay CASHFREE Only ₹ 1200</b-card-text
                >
              </b-col>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-form-radio-group>
    <b-container>
      <b-row class="mt-5 mb-3">
        <b-col class="text-center">
          <!-- <template v-if="$store.state['billPayment'].checkoutLoading">
                  <b-spinner
                    v-for="i in 3"
                    :key="i"
                    class="mr-1"
                    small
                    label="Small Spinner"
                    type="grow"
                  ></b-spinner>
              </template>-->
          <button
            v-if="activatePaymentKey"
            @click="createOrder"
            type="button"
            class="btn btn-primary btn-lg center"
          >
            <span class="button-text"></span> Confirm Order
          </button>
        </b-col>
      </b-row>
    </b-container>
    <VueCashfree
      :initialiseKey="initialiseKey"
      @onSuccess="onSuccess"
      @onFailure="onFailure"
      :orderToken="orderToken"
      :env="cashfreeEnv"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Vue from "vue";
import VueCashfree from "vue-cashfree-sdk";
export default {
  name: "payment",
  components: {
    VueCashfree,
  },
  data() {
    return {
      selected: "CASHFREE",
      cashfreeEnv: process.env.VUE_APP_CASHFREE_ENVIRONMENT,
      activatePaymentKey: true,
      initialiseKey: false,
      orderToken: "",
      packageData: {},
    };
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      userInfo: (state) => state.auth.userInfo,
      packageList: (state) => state.healthCare.specificPackageList,
      paymentMethodClick: (state) => state.billPayment.paymentMethodClick,
      checkoutData: (state) => state.checkout.checkoutData,
      productData: (state) => state.billPayment.productData,
      selectedSlotData: (state) => state.healthCare.selectedSlotData,
      selectedMemberData: (state) => state.healthCare.selectedMemberData,
      healthProducts: (state) => state.healthCare.healthProducts,
      orderId: (state) => state.billPayment.orderId,
    }),
    checkoutSubTotal() {
      let total = 0;
      for (let i = 0; i < this.checkoutData.items.length; i++) {
        total = total + this.checkoutData.items[i].finalPrice;
      }
      return total;
    },
    packageAmount() {
      if (this.packageData.mrp !== null) {
        return this.packageData.mrp;
      } else {
        return this.packageData.price;
      }
    },
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.$store.state["billPayment"].paymentMethodClick = "CASHFREE";
    if (!this.$store.state["auth"].isLoggedin) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    onFailure(err) {
      console.log(err);
      this.$swal({
        group: "item",
        title:
          "Sorry..we are not able to place your order currently , if money is deducted it will be refund out in between 7 working days.",
        text: "",
        type: "error",
      });
    },
    onSuccess(res) {
      console.log(res);
      this.orderFinalValiadtion();
    },
    async confirmOrderInit() {
      if (this.selected === "CASHFREE") {
        // await this.$store.dispatch("billPayment/getcheckoutData");
        // await this.$store.dispatch("address/fetchDeliveryAddress");
        this.initiateCashfree();
      } else {
        this.showCustomToast();
      }
    },
    showCustomToast() {
      Vue.toasted.show("Please Select Cashfree", {
        type: "info",
        position: "bottom-center",
        theme: "toasted-primary",
        singleton: true,
        duration: 3000,
      });
    },
    async changePaymentSelect(res) {
      if (res === "CASHFREE") {
        this.selected = res;
        await this.$store.dispatch("address/fetchDeliveryAddress");
        await this.$store.dispatch("billPayment/getcheckoutData");
      } else {
        this.showCustomToast();
      }
    },
    async initiateCashfree() {
      if (this.checkoutData?.payment?.pgOrderData?.payment_session_id) {
        this.orderToken = this.checkoutData?.payment?.pgOrderData?.payment_session_id;
        this.initialiseKey = false;
        setTimeout(() => {
          this.initialiseKey = true;
        }, 1);
      } else {
        this.initialiseKey = false;
      }
    },

    async createOrder() {
      let payload = {
        customerDetails: {
          orderAmount: this.checkoutData?.totalPrice,
          customerName: this.selectedMemberData?.authentication?.userName,
          customerPhone: this.selectedMemberData?.authentication?.phone,
          customerEmail: this.selectedMemberData?.authentication?.email,
        },
        orderDetails: {
          paymentType: this.checkoutData.items[0].type,
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: this.checkoutData?.items,
        },
        txData: {
          orderId: this.checkoutData?.payment?.orderId,
          referenceId: "",
          txStatus: "",
          txMsg: "",
          txTime: "",
          signature: "",
          PaymentServiceName: "",
        },
        createdAt: this.checkoutData?.createdAt,
        payments: this.checkoutData?.payments,
        payment: this.checkoutData?.payment,
        orderHash: this.checkoutData?.hash,
        isNew: true,
      };

      await this.$store
        .dispatch("billPayment/createOrderAction", payload)
        .then(async (res) => {
          console.log("order creation", res);
          await this.confirmOrderInit();
          this.$store.state["billPayment"].checkoutLoading = false;
        })
        .catch((err) => {
          this.$store.state["billPayment"].checkoutLoading = false;
          console.log("err", err);
        });
    },
    async orderFinalValiadtion() {
      let payload = {
        customerDetails: {
          orderAmount: this.checkoutData?.totalPrice,
          customerName: this.selectedMemberData?.authentication?.userName,
          customerPhone: this.selectedMemberData?.authentication?.phone,
          customerEmail: this.selectedMemberData?.authentication?.email,
        },
        orderDetails: {
          paymentType: this.checkoutData.items[0].type,
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: this.checkoutData?.items,
        },
        // txData: {
        //   orderId: this.checkoutData?.payment?.orderId,
        //   referenceId: "",
        //   txStatus: "",
        //   txMsg: "",
        //   txTime: "",
        //   signature: "",
        //   PaymentServiceName: "",
        // },
        orderId: this.checkoutData?.payment?.orderId,
        createdAt: this.checkoutData?.createdAt,
        payments: this.checkoutData?.payments,
        payment: this.checkoutData?.payment,
        orderHash: this.checkoutData?.hash,
        // isNew: true,
      };
      console.log(payload, "validate");
      payload.orderDetails.items[0].custom.healthiansPayload.vendor_booking_id =
        this.orderId;
      await this.$store
        .dispatch("billPayment/createOrdervalidateAction", payload)
        .then(async (res) => {
          console.log("order creation", res);
          this.$swal({
            group: "item",
            title: "Order Created",
            text: "",
            type: "success",
          });
          this.$router.push({
            name: "order-success",
            params: {
              orderId: this.orderId,
            },
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    continuePayment() {
      this.activatePaymentKey = true;
    },
    back() {
      history.back();
    },
    fetchPackageData() {
      this.packageData = this.packageList.find(
        (el) => el.deal_id == this.$route.params.profile
      );
    },
  },
  async mounted() {
    this.fetchPackageData();
    await this.$store.dispatch("address/fetchDeliveryAddress");
  },
};
</script>
<style scoped>
.cashfree-text {
  color: #fd7e14 !important;
  font-size: 20px !important;
}
.order-left {
  left: 150px;
}
.order-card-container {
  width: 80%;
  margin-left: 130px;
}
.total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
}
.sub-total {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
  color: #000000;
}
.price-details {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 39px;
  color: #000000;
}
.order-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 49px; */
  color: #000000;
}
.order-header-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 89px;
  color: #000000;
}
.list-group {
  margin-top: 15px;
  max-height: 540px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
